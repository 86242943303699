/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import {Link} from "gatsby"
const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div className="bio text-center">
      {avatar && (
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          className="bio-avatar"
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      )}
      {author?.name && (
        <div>
          <p>
            <strong>{author.name}</strong>     
          </p>
          <ul className="flex mt-2 justify-center">
            <li className="flex-initial p-0.5 mx-1 hover:bg-gray-default">
                <a href="https://twitter.com/yukyu30" >                    
                    <p className="text-blue-default">Twitter</p>
                </a>
            </li>
            <li className="flex-initial p-0.5 mx-1 hover:bg-gray-default">
                <Link to={"/rss.xml"} > 
                    <p className="text-blue-default">RSS Feed</p>
                </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default Bio
