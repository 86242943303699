import React from "react"
import { Link } from "gatsby"
import logo from "../images/logos/yukyu.svg"
import Bio from "../components/bio"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header


  header = (
    <div className="bg-blue-dark border-b-2 border-gray-default">
      <div className="wrapper">
        <Link to="/"><img className="navbar-brand h-10 p-2" src={logo} alt="Logo" /></Link>
      </div>
    </div>
  )


  return (
    <div className="bg-blue-dark" data-is-root-path={isRootPath}>
      <header>{header}</header>
      <div className="wrapper min-h-screen" data-is-root-path={isRootPath}>
        <main>{children}</main>
      </div>
      <footer className="border-t-2 border-gray-default">
        <div className="wrapper mt-4 text-center">
          <Bio />
         <p className="mt-4">© {new Date().getFullYear()}, yukyu</p> 
          <p>一部の絵文字には<a className="text-blue-default"href="https://twemoji.twitter.com/">Tweomji</a>を利用しています</p>
        </div>
      </footer>
    </div>
  )
}

export default Layout
